import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Java",
      desc: "Mon langage de prédilection est le Java. À l'origine, je l'utilisais principalement pour développer des applications monolithiques. Cependant, avec l'émergence de Quarkus, j'ai pu exploiter Java pour créer des API RESTful et optimiser les livrables. Quarkus offre des fonctionnalités qui permettent de développer des applications légères et réactives en utilisant Java, tout en réduisant la consommation de mémoire et en accélérant le temps de démarrage. Cela ouvre de nouvelles perspectives pour l'utilisation de Java dans le développement d'API modernes et performantes."
    },
    {
      name: "React",
      desc: "L'évolution de l'architecture vers un fort découplage entre le front-end et le back-end a suscité mon intérêt pour les frameworks front-end JavaScript. Cette évolution a ouvert de nouvelles possibilités en termes de développement d'interfaces utilisateur interactives et dynamiques. Pour répondre à ces besoins, j'ai exploré et découvert les frameworks front-end tels que React, Angular et Vue.js. Ces frameworks offrent des outils puissants pour la création d'applications web modernes, en permettant la gestion de l'état, la manipulation du DOM et la création de composants réutilisables. Cette découverte m'a permis d'élargir mes compétences et de me familiariser avec les meilleures pratiques du développement front-end."
    },
    {
      name: "VueJs",
      desc: "Il a été captivant de découvrir un framework alternatif à React, qui présente une similarité architecturale remarquable. Je fais référence au State Management Pattern, où d'un côté nous avons Vuex, et de l'autre, Redux. Ces deux bibliothèques fournissent des solutions robustes pour gérer l'état de l'application dans un contexte de développement front-end. Bien que chacune ait ses particularités, elles partagent une approche commune en facilitant la centralisation et la gestion de l'état de l'application, ce qui permet une meilleure maintenabilité et une meilleure évolutivité. Cette exploration m'a permis d'élargir ma palette d'outils et d'approfondir ma compréhension des modèles de gestion d'état dans le développement d'applications front-end."
    },
    {
      name: "React Native",
      desc: "J'ai considéré comme essentiel d'étendre mes compétences au développement mobile pour pouvoir offrir une gamme plus complète de solutions. Grâce à sa similarité avec la version web, l'adoption de React Native s'est révélée être une transition relativement aisée. Ce framework m'a permis de capitaliser sur mes connaissances existantes en React et d'appliquer efficacement mes compétences de développement front-end au domaine mobile. "
    }
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Qu'est-ce que je fais ?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
