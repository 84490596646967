import React from "react";
import ReactHtmlParser from "react-html-parser";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2017",
      title: "Développeur Logiciel",
      place: "AFPA, Brest",
      desc: "<p>Après avoir commencé à apprendre le développement en autodidacte, j'ai suivi une formation de 8 mois centrée sur l'architecture logicielle. Cette formation a été importante pour consolider mes connaissances initiales.</p><p>En tant qu'autodidacte, j'avais acquis des compétences de base en développement, mais cette formation m'a permis de développer une compréhension approfondie de la conception et de la structuration des systèmes logiciels.</p><p>Cette formation m'a également offert une opportunité d'échange avec d'autres personnes partageant les mêmes intérêts, ce qui m'a permis d'élargir ma perspective et de bénéficier des expériences et des idées des autres.</p><p>Dans l'ensemble, cette expérience de formation a été très précieuse pour mon parcours de développement. Elle m'a donné la confiance nécessaire pour aborder des projets plus complexes et m'a fourni une base solide en matière d'architecture logicielle.</p>",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "Depuis septembre 2023",
      title: "Développeur full stack freelance",
      place: "Ownily",
      technologies:
        "VueJS, Vuex, Axios, NodeJS, Moleculer, MongoDB, Docker, Gitlab",
      desc: "<p>Au sein de cette start-up qui développe un logiciel de comptabilité autonome pour les SCI et LMNP, ma mission consiste à développer de nouvelles fonctionnalités et à consolider l'existant, notamment par le biais de refactoring.</p>",
    },
    {
      yearRange: "mars 2022 - septembre 2023",
      title: "Tech Lead",
      place: "KORP Software",
      technologies:
        "Freemarker, Java, JEE, Spring, Hibernate, Jenkins, Docker, Gitlab",
      desc: "<p>Au sein de cet éditeur de logiciels, j'ai eu l'opportunité de prendre des responsabilités plus importantes en prenant en charge le service de développement. Ma mission principale était de faire évoluer l'application phare axée sur le domaine des économies d'énergie.</p><p>En plus de cela, j'étais également chargé d'assurer la maintenance corrective de l'application, ce qui impliquait de fixer les priorités en termes de ticketing et de maintenir une communication constante avec le support client. Cette expérience m'a permis de développer davantage mes compétences en gestion de projet et en coordination d'équipe, tout en me donnant une vision plus globale du cycle de vie d'un produit logiciel.</p>",
    },
    {
      yearRange: "septembre 2020 - février 2022",
      title: "Ingénieur d'Etudes",
      place: "KORP Software",
      technologies: "Freemarker, Java, JEE, Spring, Hibernate, Jenkins, Gitlab",
      desc: "<p>En tant que développeur au sein de cet éditeur de logiciels, j'ai eu l'opportunité de contribuer au développement de nouvelles fonctionnalités pour le module RH ainsi que pour le cœur du framework interne. Travailler sur le framework interne m'a permis d'approfondir mes connaissances en développement générique.</p><p>Parallèlement, j'ai également été impliqué dans deux nouveaux projets : l'un dans le domaine des avocats et l'autre dans le domaine des économies d'énergie. Cette expérience m'a offert une perspective diversifiée et m'a permis d'explorer de nouveaux secteurs, élargissant ainsi ma palette de compétences et enrichissant mon bagage professionnel.</p>",
    },
    {
      yearRange: "septembre 2018 - août 2020",
      title: "Référent Technique Web",
      place: "Sodifrance (Suravenir)",
      technologies:
        "JSP, Vaadin, Liferay, Java, JEE, Apache CXF, Apache Camel, Hadoop, PIG, Oracle, Jenkins, SVN",
      desc: "<p>J'ai eu l'opportunité de devenir rapidement le référent technique de l'équipe de Tierce Maintenance Applicative sur la partie web. En plus de continuer à résoudre les incidents de production, j'ai été chargé de résoudre les problèmes les plus critiques. En tant que référent, j'ai également eu la responsabilité de gérer les priorités des tickets et de répartir la charge de travail au sein de mon équipe.</p><p>J'ai été impliqué dans l'intégration des nouveaux membres, les présentations techniques, et j'ai joué le rôle d'interlocuteur privilégié avec les équipes internes, que ce soit le support client, la MOA ou la MOE. Ces responsabilités étaient une partie intégrante de mon quotidien dans ce rôle de référent technique.</p>",
    },
    {
      yearRange: "janvier 2018 - août 2018",
      title: "Analyste Programmeur",
      place: "Sodifrance (Suravenir)",
      technologies:
        "JSP, Vaadin, Liferay, Java, JEE, Apache CXF, Apache Camel, Oracle, SVN",
      desc: "<p>J'ai eu l'opportunité d'intégrer la Tierce Maintenance Applicative de Suravenir, la filiale assurance du Crédit Mutuel Arkéa. Dans ce rôle, j'étais chargé de résoudre les incidents de production sur une variété de projets et d'applications.</p><p>Cette expérience a été très enrichissante et a considérablement renforcé ma capacité d'analyse grâce à la diversité des projets auxquels j'ai pu contribuer. Travailler sur différentes applications m'a permis de développer une compréhension approfondie des systèmes existants et d'acquérir une expertise dans la résolution rapide et efficace des problèmes.</p>",
    },
    {
      yearRange: "octobre 2017 - décembre 2017",
      title: "Développeur Web",
      place: "Equantec",
      technologies: "AngularJS, NodeJS, PHP, Codeigniter, PostgreSQL, Git",
      desc: "<p>J'ai commencé mon parcours au sein de cette entreprise en tant que stagiaire, et au cours de cette période, j'ai eu l'opportunité de mettre en pratique mes connaissances acquises dans un environnement professionnel. L'application sur laquelle j'ai travaillé pendant mon stage était dédiée au suivi des dossiers d'analyses de structures amiantées.</p><p>Ce fut une expérience précieuse pour moi, car elle m'a permis de voir concrètement comment mes compétences pouvaient être appliquées dans un contexte professionnel. L'entreprise a été satisfaite de mon travail, au point de me proposer un contrat à durée indéterminée à la moitié de mon stage. Cela a été une reconnaissance gratifiante de mes compétences et de ma contribution au sein de l'équipe.</p>",
    },
  ];

  const skills = [
    {
      name: "Spring",
    },
    {
      name: "Hibernate",
    },
    {
      name: "Jenkins",
    },
    {
      name: "Docker",
    },
    {
      name: "Git/GitLab",
    },
    {
      name: "SQL",
    },
    {
      name: "Redux",
    },
    {
      name: "Vuex",
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Résumé
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Mon parcours
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Mes diplômes
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {ReactHtmlParser(value.desc)}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              Mes expériences
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"fw-bold " + (darkTheme ? "text-white" : "")}>
                    {value.technologies}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {ReactHtmlParser(value.desc)}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          Mes compétences
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}
                </p>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Télécharger CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
