import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            A propos de moi
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Me connaître davantage
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              Je suis <span className="text-primary">Benoist Babin,</span> Développeur
              Web
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              Autodidacte passionné par le code et l'architecture logicielle dans son ensemble.
              Les divers environnements dans lesquels j'ai évolué m'ont permis d'acquérir une certaine polyvalence.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            En plaçant le clean code au cœur de ma démarche, j'aspire à produire un code lisible et bien structuré en suivant les meilleures pratiques de développement.
            Cette approche revêt pour moi une importance capitale, car elle garantit la durabilité et la capacité d'évolution des projets sur le long terme. 
            Mon souci de la qualité du produit final et de la satisfaction du client m'amène à veiller à la maintenabilité et à l'évolutivité du code, de manière à répondre aux besoins en constante mutation du marché.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Nom:</span>Benoist Babin
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:mail@benoistbabin.fr">mail@benoistbabin.fr</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>31
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">Localisation:</span>Landerneau,
                  France
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Télécharger CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-12">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>{/*6*/}</span>{/*+*/}
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {/*Années d'expérience*/}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;